import React, { useState } from 'react';

// import useChannels from '../store/useChannels';
import useCurrentChannel from '../store/useCurrentChannel';
import PlayIcon from '../icons/PlayIcon';
import { Button, message } from 'antd';
import { method } from 'lodash-es';


export default function Footer(props) {
    const { like } = props
    const [currentChannel, currentChannelActions] = useCurrentChannel();
    console.log('footer currentChannel :', currentChannel);
    // const [channelsState, channelsActions] = useChannels();

    const [newChannel, setNewChannel] = useState(currentChannel);

    const onPlayChannelChange = e => {
        // console.log("new channel:", newChannel)
        // console.log("e.target.value", e.target.value)
        setNewChannel({ ...newChannel, [e.target.name]: e.target.value });
    }

    const onPlayChannel = e => {
        e.preventDefault();
        currentChannelActions.set(newChannel);
    }

    // const onAddChannel = () => {
    //     if (newChannel.type === 'iframe') { channelsActions.addNewIframeChannel(newChannel); }
    //     else { channelsActions.addNew(newChannel); }
    // }

    const onReset = () => {
        setNewChannel({
            name: 'Harlen',
            type: "m3u8",
            url: ''
        })
        currentChannelActions.set({
            name: 'Harlen',
            type: "m3u8",
            url: ''
        })
    }

    return <>
        <form id="footer" onSubmit={onPlayChannel}>
            <div className='w-100'>
                <label></label>
                <input className='url-input' type="url" name='url'
                    value={newChannel.url}
                    onChange={onPlayChannelChange}
                    placeholder='输入视频地址'
                    required
                />
                <Button onClick={() => {
                    const headers = new Headers()
                    headers.append('content-type', 'application/json')
                    fetch('/likes', {
                        method: 'post',
                        headers,
                        body: JSON.stringify(like)
                    }).then((res) => {
                        message.success("添加成功")
                    })
                }}>加入收藏</Button>
                <select className='media-select' name='type' value={newChannel.type} onChange={onPlayChannelChange}>
                    <option value="m3u8">m3u8</option>
                    <option value="mp4">mp4</option>
                    <option value="webm">webm</option>
                    <option value="iframe">iframe</option>
                </select>

            </div>

            {/* <input className='br7' type="text" name="name"
          value={newChannel.name}
          onChange={onPlayChannelChange}
          placeholder='Enter channel name: CNN' required /> */}



            <div className='w-100 d-flex justify-between mb-1 mt-1'>
                <button className='w-100 shadow br7 mr-1' type='submit' title='Play'>
                    <PlayIcon /><span className='ml-1'>Play</span>
                </button>

                <button className='w-100 shadow br7 ml-1 bg-red' type='reset' onClick={onReset}>Reset</button>
            </div>
        </form>

        {/* <button className='w-100 bg-blue mr-2 shadow br7' onClick={onAddChannel} title="Add To Favorites">
        <PlusIcon />
        <span className='ml-2'>Add to favorites</span>
      </button> */}
    </>

}
