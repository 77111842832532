import React, { Component, useCallback, useEffect, useState } from 'react';
import VideoContainer from './components/VideoContainer';
import useCurrentChannel from './store/useCurrentChannel';
import Footer from './components/Footer';
import playerStyle from './index.module.css'
import './index.css'
import parse from "../Welfare/parse";
import { get } from 'lodash-es';

export default function App() {
  const [url, setUrl] = useState("")
  const [currentChannel, currentChannelActions] = useCurrentChannel();
  const [newChannel, setNewChannel] = useState(currentChannel);
  const [like,setLike] = useState({})
  const [orginUrl,setOriginUrl] = useState("")
  const [params,setParams] = useState({})
  function parseUrl(url) {
    let obj = {};
    url
      .slice(url.indexOf("?") + 1)
      .split("&")
      .map(item => {
        let [key, val] = item.split("=");
        obj[key] = decodeURIComponent(val);
      });
    return obj;
  }

  useEffect(() => {
    if (window.location.href.indexOf("?") != -1){
      let params = parseUrl(window.location.href)
      console.log('params :', params);

      setOriginUrl(params.origin_url)
      setUrl(params.url)
      // 存进数据库的url直接定为player的url
      params.url = window.location.href
      setParams(params)
      setLike(params)
      console.log('params.url :', params.url);
    }
      
  }, [window.location.href])

  useEffect(()=>{
    if(!orginUrl)return;
    console.log('orginUrl :', orginUrl);
    const urlFn = async ()=>{
      let url = await parse(orginUrl,params.time,true,true)
      console.log('url :', url);
      setUrl(url)
    }
    urlFn()
  },[orginUrl])

  useEffect(() => {
    if(!url)return
    console.log('url :', url);
    if (url.endsWith(".mp4"))
      setNewChannel({ ...newChannel, url: url, type: 'mp4' });
    else
      setNewChannel({ ...newChannel, url: url });
  }, [url]);

  useEffect(() => {
    console.log("newChannel index", newChannel)
    if(newChannel.url){
      currentChannelActions.set(newChannel);
    }
  }, [newChannel])


  return <div className={playerStyle.appContainer}>
    <main>
      <section>
        <div className={playerStyle.content} style={{ height: "100%" }}>
          <div className={playerStyle.videoContainer}>
            <VideoContainer />
          </div>
        </div>
      </section>
      
      <Footer like = {like}/>
    </main>
  </div>

};
