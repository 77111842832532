import React from "react";
import { Route, Routes } from "react-router-dom";
import Player from "./pages/Player";
import Welfare from "./pages/Welfare";
import Collects from "./pages/Collects";


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Player />}></Route>
      <Route path="/player" element={<Player />}></Route>
      <Route path="/welfare" element={<Welfare />}></Route>
      <Route path="/collects" element={<Collects />}></Route>
      {/* <Route path="/shop" component={Shop} /> */}
    </Routes>
  );
};

export default App;
