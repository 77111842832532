import { useEffect, useLayoutEffect, useState } from "react";
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import parse from "./parse";
import { Layout, Menu, Input, Space, Col, Row, Pagination, Dropdown, Button, List } from 'antd';
import { Link } from "react-router-dom";
import './index.css';
const { Search } = Input;
const { Header, Footer, Sider, Content } = Layout;
const proxy_url = 'https://hls-api.cxbd.world';
const sites = [
  {
    label: '91rebao',
    key: '_91rebao',
  },
  {
    label: 'moav',
    key: 'moav',

  },
  {
    label: 'youhu',
    key: 'youhu',
  },
  {
    label: 'resao',
    key: 'resao',
  },
  // {
  //   label: '99re',
  //   key: '_99re'
  // },
  {
    label: 'avjb',
    key: 'avjb'
  }
];
let urls = {
  moav: proxy_url + '/moav',
  _91rebao: proxy_url + '/91rebao',
  resao: proxy_url + '/resao',
  youhu: proxy_url + '/youhu',
  // _99re: proxy_url + '/99b90',
  avjb: proxy_url + '/avjb'
}


function find(str, cha, num) {
  var x = str.indexOf(cha);
  for (var i = 0; i < num; i++) {
    x = str.indexOf(cha, x + 1);
  }
  return x;
}


export default function App() {
  const [current, setCurrent] = useState('_91rebao');
  const [search, setSearch] = useState(null);
  const [content, setContent] = useState('');
  const [page, setPage] = useState('1')
  const [state, setState] = useState(false)
  const [sortType, setSortType] = useState('post_date')

  const search_path = '/search/?mode=async&function=get_block&block_id=list_videos_videos_list_search_result&category_ids=&q='
  const latest_path = (current == 'avjb' ? '/?mode=async&function=get_block& block_id=list_videos_most_recent_videos&sort_by=post_date&from=' :
    '/latest-updates/?mode=async&function=get_block&block_id=list_videos_latest_videos_list&sort_by=post_date&from=')

  const items = [
    {
      label: (<a onClick={() => { setSortType('post_date') }}>最新视频</a>),
      key: 'post_date'
    },
    {
      label: (<a onClick={() => { setSortType('video_viewed') }}>播放最多</a>),
      key: 'video_viewed'
    },
    {
      label: (<a onClick={() => { setSortType('rating') }}>评分最高</a>),
      key: 'rating'
    },
  ];

  const construct_url = () => {
    let url
    if (!search) {
      url = urls[current] + latest_path + page

    } else {
      url = urls[current] + search_path + search
      url += '&sort_by=' + sortType
      if (page != 1) {
        url += "&from_videos=" + page
      } else {
        if (current == 'moav') {
          url += "&from_videos=" + page
        }
      }
    }
    return url
  }

  useEffect(() => {
    setContent('')
    let url = construct_url()
    fetch(url, { referrer: "" })
      .then((reponse) => {
        if (reponse.ok)
          return reponse.text()
        else
          throw Error("url visit failed")
      })
      .then((text) => {
        // console.log(text)
        setContent(text)
      })
      .catch((error) => {
        setContent("<h1 id='failed'>无内容</h1>")
        console.log(error)
      })
  }, [page, state])

  useEffect(() => {
    // console.log("state要发生变化")
    setPage(1)
    setState(!state)
  }, [current, search, sortType])


  // 修改获取到的内容
  useEffect(() => {
    if (!content) return
    let box = document.getElementById('list-box');
    box.innerHTML = content

    // 修改图片显示
    let selects = null
    if (search) {
      selects = document.querySelectorAll("#list_videos_videos_list_search_result_items > div > a > div.img >img")
    } else {
      if(current=='avjb'){
        selects = document.querySelectorAll("#list_videos_most_recent_videos_items > div > a > div.img >img")
      }else{
        selects = document.querySelectorAll("#list_videos_latest_videos_list_items > div > a > div.img >img")
      }
    }
    // console.log(selects)
    selects.forEach((select) => {
      // console.log(select)
      let src = select.getAttribute("data-original");
      if(src.startsWith('/') && current === 'youhu'){
        src = 'https://www.youhu.com' + src;
      }
      select.src = src
    })

      
    // 修改链接跳转
    let hrefs=null
    if (search) {
      hrefs = document.querySelectorAll('#list_videos_videos_list_search_result_items > div > a')
    } else {
      if(current=='avjb'){
        hrefs = document.querySelectorAll('#list_videos_most_recent_videos_items > div > a')
      }else{
        hrefs = document.querySelectorAll('#list_videos_latest_videos_list_items > div > a')
      }
    }
    hrefs.forEach((href) => {
      href.target = "_blank"
    })
    hrefs.forEach(async (href) => {
      let url = href.href;
      let time = href.querySelector('a > div > div.duration').textContent
      // console.log(time)
      if(current=='_91rebao'||current=='moav'||current=='youhu'){
        url = await parse(url, time,false)
      }else{
        url = await parse(url, time)
      }
      console.log('url :', url);
      // 添加titile和img字段,time
      let params = new URLSearchParams()
      params.append('title', href.title)
      params.append('time', time)
      let img_url = href.querySelector('a > div > img').getAttribute("data-original")
      params.append('img', img_url)
      href.href = url + '&' + params.toString()

      console.log("after url:", url)
    })

    // 拿到待删除节点:
    var self = document.getElementById('list_videos_videos_list_search_result_pagination');
    if (self) {
      // 拿到父节点:
      var parent = self.parentElement;
      // 删除:
      var removed = parent.removeChild(self);
    }

    var self = document.getElementById('list_videos_latest_videos_list_pagination');
    if (self) {
      // 拿到父节点:
      var parent = self.parentElement;
      // 删除:
      var removed = parent.removeChild(self);
    }

    var self = document.getElementById('list_videos_most_recent_videos_pagination');
    if (self) {
      // 拿到父节点:
      var parent = self.parentElement;
      // 删除:
      var removed = parent.removeChild(self);
    }

    var self = document.querySelector('#list_videos_latest_videos_list > div.mobile-paginator')
    if (self) {
      // 拿到父节点:
      var parent = self.parentElement;
      // 删除:
      var removed = parent.removeChild(self);
    }

    var selfs = document.querySelectorAll('#list_videos_videos_list_search_result_items > div > a > div > div.rating.positive > span');
    if (selfs) {
      selfs.forEach((self) => {
        var parent = self.parentElement;
        console.log(parent)
        var removed = parent.removeChild(self);
        // removed === self;
      })
    }
    
    let ratings = Array.from(document.getElementsByClassName('rating_circle')) 
    console.log('ratings :', ratings);
    if(ratings){
      ratings.forEach((e)=>{
        let parent = e.parentElement
        parent.removeChild(e)
      })
    }
    
  }, [content])

  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  const onSearch = (value) => {
    console.log(value);
    setSearch(value)
  }
  const sortToLabel = (value) => {
    return value == 'post_date' ? '最新视频' : (value == 'video_viewed' ? '播放最多' : '评分最高')
  }


  return <>
    <Layout>
      <Header id="firstHeader">
        <div id="name">福利资源</div>
        <Search
          size="large"
          placeholder="搜索~"
          className="nav-search"
          onSearch={onSearch}
          enterButton
        />
        <div>
          <Link
            style={{ color: "#1677ff",marginRight:'10px' }}
            to={{
              pathname: '/collects',
              // state: {  // 页面跳转要传递的数据，如下
              //   data1: {},
              //   data2: []
              // },
            }}
          >收藏</Link>
        </div>
      </Header>
      <>
        <Header id="navHeader">
          <Menu theme="light" onClick={onClick} selectedKeys={[current]} mode="horizontal" items={sites} />

        </Header>


        <Content>
          {/* <iframe srcDoc={content}></iframe> */}
          <div className="tools">
            <div className="flush">
              <Button onClick={() => { setState(!state) }}>刷新</Button>
            </div>
            <div id="sort">
              <Dropdown menu={{ items }} placement="bottom" overlayStyle={{ width: '140px', textAlign: 'center' }} arrow>
                <Space>
                  <div className="sort-view">{sortToLabel(sortType)}</div>
                </Space>
              </Dropdown>
            </div>
          </div>
          <div id="list-box" >
            请搜索
          </div>

        </Content>
        <Footer style={{
          display: 'flex',
          justifyContent: "center",
        }}>
          <Pagination simple current={page} total={5000} onChange={(page, pageSize) => { setPage(page) }} />
        </Footer>



      </>


    </Layout>

  </>
}