import Layout, { Content, Header } from "antd/es/layout/layout"
import { useEffect, useState } from "react"
import { List } from 'antd'


const Collects = () => {
    const [likeList, setLikeList] = useState([])

    const getLikeList = async () => {
        let data = await fetch('/likes')
        data = await data.json()
        data = data["results"]

        setLikeList(data)
    }
    useEffect(() => {
        getLikeList()
    }, [])
    return <div>
        <Layout >
            <Header style={{ background: "transparent" }}>
                <h1>我的收藏页</h1>
            </Header>
            <Content>
                <List
                    header={<div>收藏列表</div>}
                    // footer={<div>Footer</div>}
                    bordered
                    dataSource={likeList}
                    renderItem={(item) => {
                        let {title, url, img} = item
                        console.log('img :', img);
                        console.log('url :', url);
                        console.log('title :', title);

                        return <>
                            <a href={url} title={title} />
                            <List.Item
                                key={title}
                                actions={[
                                    // <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                                    // <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                                    // <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                                ]}
                                extra={
                                    <img
                                        width={272}
                                        alt={title}
                                        src={img}
                                    />
                                }
                            >
                                <List.Item.Meta
                                    // avatar={<Avatar src={item.avatar} />}
                                    title={<a href={url}>{title}</a>}
                                    // description={<a href={url}>{title}</a>}
                                />
                                
                            </List.Item>
                        </>
                    }}
                />
            </Content>
        </Layout>



    </div>
}

export default Collects